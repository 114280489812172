/* Header Component */

header {
    display: block;
    height: 15vh;
    background-color: rgb(40, 40, 32);
    /* display: flex;
    justify-content: center; */
    font-family: Mukta Vaani;
}

header h1{
    margin-top: -3vh;
    font-size: 60px;
    color: rgb(134, 120, 69);
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 315px) and (max-width: 374px){
    header {
    display: block;
    height: 10vh;
    background-color: rgb(40, 40, 32);
    font-family: Mukta Vaani;    
    padding-bottom: 2vh;

}

header h1{
    margin-top: -3.1vh;
    padding:1vh;
    font-size: 35px;
    color: rgb(134, 120, 69);
    position: sticky;
    margin-bottom: 5%;
    /* width: 100%; */
   
}
}

@media screen and (min-width: 375px) and (max-width: 424px){
    header {
    display: block;
    height: 10vh;
    background-color: rgb(40, 40, 32);
    font-family: Mukta Vaani;    
    padding-bottom: 2vh;

}

header h1{
    margin-top: -3.5vh;
    padding:1vh;
    font-size: 35px;
    color: rgb(134, 120, 69);
    position: sticky;
    margin-bottom: 5%;
}
}

@media screen and (min-width: 425px) and (max-width: 767px){
    header {
    display: block;
    height: 12vh;
    background-color: rgb(40, 40, 32);
    font-family: Mukta Vaani;    
    padding-bottom: 6vh;

}

header h1{
    margin-top: -5vh;
    padding-top:2vh;
    margin-left: -3.5vh;
    font-size: 42px;
    color: rgb(134, 120, 69);
}
}

@media screen and (min-width: 768px) and (max-width: 1023px){
    header {
    display: block;
    height: 12vh;    
    background-color: rgb(40, 40, 32);
    font-family: Mukta Vaani;
}

header h1{
    margin-top: -2.8vh;
    font-size: 55px;
    color: rgb(134, 120, 69);
    margin-left: 1%;
   
}
}

@media screen and (min-width: 2560px) {
    header {
    display: block;
    height: 12vh;    
    background-color: rgb(40, 40, 32);
    font-family: Mukta Vaani;    

}

header h1{
    margin-top: -3vh;
    font-size: 80px;
    color: rgb(134, 120, 69);
    margin-left: 1%;
   
}
}

