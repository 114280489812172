/* Navbar */

.navContainer {
    width: 100%;
    display:block;
    align-items: center;
    background-color: rgb(40, 40, 32);

}

.navTabs {
    width: 40%;
    margin-left: 27%;
    display:flex;
    justify-content:space-around;
    text-decoration: none;
    list-style: none;
}

.navBtn {
 margin-top: 5vh;
}

.link {
    font-family: Mukta Vaani;
    text-decoration: none;
    list-style: none;
    color: rgb(168, 168, 163);
    /* font-weight: bold; */
}

.link:hover {
    font-style: italic;
}

@media screen and (min-width: 315px) and (max-width: 424px){

.navTabs {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-left: 12%;
    text-decoration: none;
    list-style: none;
}

.navBtn {
    display: flex;
    justify-content: space-around;
    margin-top: 1.5vh;
    font-size: large;
}

#homeLink{
    margin-left: 4vh;
    
}

#calLink{
    margin-left: 12vh;
}

#musicLink{
    margin-left: 4vh;
}

#contactLink{
    margin-left: 12vh;
}
}

@media screen and (min-width: 375px) and (max-width: 424px){

.navTabs {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-left: 18%;
    text-decoration: none;
    list-style: none;
}

.navBtn {
    display: flex;
    justify-content: space-around;
    margin-top: 1vh;
    font-size: large;
}

#homeLink{
    margin-left: 4vh;
    
}

#calLink{
    margin-left: 12vh;
}

#musicLink{
    margin-left: 4vh;
}

#contactLink{
    margin-left: 12vh;
}
}

@media screen and (min-width: 425px) and (max-width:767px){

.navTabs {
    display: flex;
    justify-content: space-around;
    margin-left: 15%;
    text-decoration: none;
    list-style: none;
}

.navBtn {
    display: flex;
    justify-content: space-around;
    margin-top: 2.7vh;
    font-size: large;
}

#homeLink{
    margin-left: 2.5vh;
    
}

#calLink{
    margin-left: 3.5vh;
}

#musicLink{
    margin-left: 3.5vh;
}

#contactLink{
    margin-left: 3.5vh;
}
}

@media screen and (min-width: 768px) and (max-width:1023px){

.navTabs {
    display: flex;
    justify-content: space-around;
    margin-left: 22%;
    text-decoration: none;
    list-style: none;
}

.navBtn {
    display: flex;
    justify-content: space-around;
    margin-top: 2vh;
}

#homeLink{
    margin-left: 10vh;
    
}

#calLink{
    margin-left: 3vh;
}

#musicLink{
    margin-left: 3vh;
}

#contactLink{
    margin-left: 3vh;
}
}

@media screen and (min-width: 2560px){

.navTabs {
    display: flex;
    justify-content: space-around;
    margin-left: 26%;
    text-decoration: none;
    list-style: none;
    padding-bottom: 2vh;
}

.navBtn {
    display: flex;
    justify-content: space-around;
    margin-top: 3vh;
}

#homeLink{
    margin-left: 10vh;
    font-size: x-large;
    
}

#calLink{
    margin-left: 3vh;
    font-size: x-large;

}

#musicLink{
    margin-left: 3vh;
    font-size: x-large;

}

#contactLink{
    margin-left: 3vh;
    font-size: x-large;

}
}