@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@200;400;800&family=Marcellus+SC&family=Mukta+Vaani:wght@200;300;500&family=Tenor+Sans&display=swap");

.bioContainer {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  margin-top: 30px;
  margin-left: 10%;
  background-color: rgba(255, 251, 244, 0.787);
  width: 80%;
}
.mainPic {
  width: 8%;
  margin-left: 25%;
  margin-top: 15%;
}

.bioText {
  color: rgb(1, 1, 1);
  padding: 10%;
  padding-right: 20%;
  width: 60%;
  margin-left: 10%;
  font-family: Cormorant Garamond;
  font-size: large;
}

@media screen and (min-width: 315px) and (max-width: 374px) {
  .bioContainer {
    display: grid;
    grid-template-columns: 280px;
    margin-top: 10px;
    margin-left: 2.5%;
    background-color: rgba(255, 251, 244, 0.847);
    width: 95%;
  }
  .mainPic {
    width: 100%;
    align-self: start;
    margin-left: 7%;
    margin-top: 5%;
  }

  .bioText {
    color: rgb(1, 1, 1);
    margin-top: -5%;
    width: 100%;
    margin-left: -2%;
    font-family: Cormorant Garamond;
    font-size: larger;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .bioContainer {
    display: grid;
    grid-template-columns: 340px;
    margin-top: 10px;
    margin-left: 2.5%;
    background-color: rgba(255, 251, 244, 0.787);
    width: 91%;
  }
  .mainPic {
    width: 100%;
    align-self: start;
    margin-left: 3.2%;
    margin-top: 3%;
  }

  .bioText {
    color: rgb(1, 1, 1);
    margin-top: -5%;
    width: 100%;
    margin-left: -5%;
    font-family: Cormorant Garamond;
    font-size: large;
  }
}

@media screen and (min-width: 425px) and (max-width: 765px) {
  .bioContainer {
    display: grid;
    grid-template-columns: 340px;
    margin-top: 10px;
    margin-left: 6%;
    background-color: rgba(255, 251, 244, 0.883);
    width: 77%;
  }
  .mainPic {
    width: 95%;
    align-self: start;
    margin-left: 3.2%;
    margin-top: 3%;
  }

  .bioText {
    color: rgb(1, 1, 1);
    margin-top: -5%;
    width: 95%;
    margin-left: -5%;
    font-family: Cormorant Garamond;
    font-size: larger;
  }
}

@media screen and (min-width: 768px) and (max-width:1024px){
  .bioContainer {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin-top: 30px;
    margin-left: 5%;
    background-color: rgba(255, 251, 244, 0.787);
    width: 88%;
  }
  .mainPic {
    width: 95%;
    align-self: start;
    margin-left: 6%;
    margin-top: 15%;
  }

  .bioText {
    color: rgb(1, 1, 1);
    margin-top: -5%;
    width: 90%;
    margin-left: -5%;
    font-family: Cormorant Garamond;
    font-size: larger;
  }
}

@media screen and (min-width: 900px){
  .bioContainer {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin-top: 30px;
    margin-left: 5%;
    background-color: rgba(255, 251, 244, 0.787);
    width: 90%;
  }
  .mainPic {
    width: 95%;
    align-self: start;
    margin-left: 6%;
    margin-top: 8%;
    margin-bottom: 8%;
  }

  .bioText {
    color: rgb(1, 1, 1);
    margin-top: -5%;
    width: 90%;
    margin-left: -5%;
    font-family: Cormorant Garamond;
    font-size: larger;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .bioContainer {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin-top: 30px;
    margin-left: 4%;
    background-color: rgba(255, 251, 244, 0.787);
    width: 90%;
  }
  .mainPic {
    width: 95%;
    align-self: start;
    margin-left: 6%;
    margin-top: 8%;
    margin-bottom: 8%;
  }

  .bioText {
    color: rgb(1, 1, 1);
    margin-top: -5%;
    width: 90%;
    margin-left: -5%;
    font-family: Cormorant Garamond;
    font-size: larger;
  }
}
