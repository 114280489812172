/* contact */

.contactContainer{
    width:60%;
    background-color: rgba(206, 209, 212, 0.756);
    display: block;
    align-self: center;
    margin:5%;
    margin-left: 19%;
    padding:15px;
    border-color: rgb(7, 7, 7);
    border-style: solid;
}

.contact {
    display: flex;
    justify-content: space-around;
    padding: 10px;
}

input {
    width:30rem;
    height: 2rem;
}

textarea {
    width:30rem;
    height: 10rem;
}


.form h2 {
    display: flex;
    justify-content: center;
    font-family: Cormorant Garamond;
    color: rgb(8, 8, 8);
}

.formBtn {
    font-family: Cormorant Garamond;
    display: flex;
    justify-content: center;
    margin-left: 40%;
    padding: 3%;
    background-color: rgba(232, 237, 237, 0.775);
}

.formBtn:hover {
    font-style: italic;
    background-color: rgb(250, 253, 255);
}

@media screen and (max-width: 426px) {
    .contactContainer{
        width:80%;
        background-color: rgba(240, 248, 255, 0.292);
        display: block;
        align-self: center;
        margin:5%;
        padding:15px;
        border-color: aliceblue;
        border-style: solid;
    }
    .contact {
        display: flex;
        justify-content: space-around;
        padding: 10px;
        font-family: tenor sans;
    }
    
    input {
        width:15rem;
        height: 2rem;
    }
    
    textarea {
        width:15rem;
        height: 10rem;
    }
    .formBtn {
        display: flex;
        justify-content: center;
        margin-left: 40%;
        padding: 3%;
        background-color: rgba(197, 219, 221, 0.895);
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .contactContainer{
        width:70%;
        background-color: rgba(240, 248, 255, 0.292);
        display: block;
        align-self: center;
        margin:5%;
        margin-left: 15%;
        padding:15px;
        border-color: aliceblue;
        border-style: solid;
    }
    .contact {
        display: flex;
        justify-content: space-around;
        padding: 10px;
        font-family: tenor sans;
    }
    
    input {
        width:25rem;
        height: 2rem;
    }
    
    textarea {
        width:25rem;
        height: 10rem;
    }
    .formBtn {
        display: flex;
        justify-content: center;
        margin-left: 40%;
        padding: 3%;
        background-color: rgba(197, 219, 221, 0.895);
    }
}

