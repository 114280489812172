
.vidContainer {
    display: grid;
    grid-template-columns: 20rem 20rem;
    width: 80%;
    margin-top: 25px;
    margin-left: 10%;
}

.vidContainerOne,
.vidContainerTwo,
.vidContainerThree,
.vidContainerFour{
    margin-left: 5%;
    margin-top: 5%;
} 

.vidOne,
.vidTwoTwo,
.vidThree,
.vidFour {
    width: 100%;
    height: 100%;
}



@media screen and (max-width: 426px) {
  .vidContainer {
    grid-template-columns: 100%;
    width: 90%;
    margin-left: 5%;
  }

  .vidContainerOne,
  .vidContainerTwo,
  .vidContainerThree,
  .vidContainerFour {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media screen and (min-width: 427px) and (max-width: 768px) {
    .vidContainer {
      grid-template-columns: 50% 50%;
      width: 80%;
      margin-left: 10%;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .vidContainer {
      grid-template-columns: 35% 35%;
      width: 80%;
      margin-left: 20%;
    }
  }


  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    .vidContainer {
      grid-template-columns: 40% 40%;
      width: 80%;
      margin-left: 20%;
    }

    .vidContainerOne,
    .vidContainerTwo,
    .vidContainerThree,
    .vidContainerFour {
      margin-left: 5%;
      margin-top: 5%;
    }
  }

  @media screen and (min-width: 1441px) {
    .vidContainer {
      grid-template-columns: 20rem 20rem;
      width: 80%;
      margin-left: 28%;
    }
  }