/* Calendar */

.calendar{
    opacity: 80%;
    margin: 5%;
    margin-left: 10%;
}

@media screen and (min-width: 315px) and (max-width: 1023px) {
.calendar{
    opacity: 90%;
    margin-top: 5%;
    margin-left: 2.5%;
}

Iframe{
    overflow-x: scroll;
    width: 100%;
}
}

@media screen and (min-width: 1024px) and (max-width: 2559px) {
.calendar{
    opacity: 90%;
    margin-top: 5%;
    margin-left: 5%;
}

Iframe{
    overflow-x: scroll;
    width: 100%;
}
}
