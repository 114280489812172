@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@200;400;800&family=Bebas+Neue&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,600;0,700;1,300&family=Mukta+Vaani:wght@200;300;500&family=Murecho:wght@200;300;400&family=Raleway:ital,wght@0,200;0,300;0,500;1,200&display=swap');


.backgroundImg {
    background-image: url('./utilities/bob-background.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative; 
    height: 80rem;
}

@media screen and (min-width: 315px) and (max-width: 3000px){
    .backgroundImg {
    background-image: url('./utilities/bob-background.JPG');
    background-repeat: no-repeat;
    position: relative; 
    height: auto;
    padding-bottom: 40px;
 
}
}
@media screen and (min-width: 315px) {
    .backgroundImg {
        height: fit-content;
    }
}

